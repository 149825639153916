import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export const pressMentions = [
  // BBC
  {
    coverImage: (
      <StaticImage
        src="../../../assets/images/media/cover_images/bbc_cover_image.jpg"
        alt="BBC Cover Image"
      />
    ),
    logo: (
      <StaticImage
        src="../../../assets/images/media/bbc.webp"
        alt="BBC News logo"
        width={88}
      />
    ),
    date: `28th Feb, 2021`,
    title: `Cryptocurrencies: Why Nigeria is a global
    leader in Bitcoin trade`,
    excerpt: `There are also fears that cryptocurrency could become a missed opportunity, according to Gbite Oduneye who `,
    url: `https://www.bbc.com/news/world-africa-56169917`,
  },

  // CNBC
  {
    coverImage: (
      <StaticImage
        src="../../../assets/images/media/cover_images/cnbc_cover_image.webp"
        alt="CNBC Cover Image"
      />
    ),
    logo: (
      <StaticImage
        src="../../../assets/images/media/cnbc.webp"
        alt="CNBC News logo"
        width={156}
      />
    ),
    date: `9th Apr, 2021`,
    title: `Nigeria’s SEC moves to stop online platforms from trading in foreign securities`,
    excerpt: `Nigeria’s Securities and Exchange Commission says online investment and trading platforms facilitating the…`,
    url: `https://www.cnbcafrica.com/2021/nigerias-sec-moves-to-stop-online-platforms-from-trading-in-foreign-securities/`,
  },

  // Proshare
  {
    coverImage: (
      <StaticImage
        src="../../../assets/images/media/cover_images/proshare_cover_image.jpg"
        alt="Proshare Cover Image"
      />
    ),
    logo: (
      <StaticImage
        src="../../../assets/images/media/proshare.webp"
        alt="Proshare logo"
        width={129}
      />
    ),
    date: `4th May, 2021`,
    title: `How Egmarkets is Changing the Financial
    Services Story in Nigeria`,
    excerpt: `The new Egmarkets can be expected to roll out even more efficient and better curated financial literacy training`,
    url: `https://www.proshareng.com/news/CAPITAL%20MARKET/How-Egmarkets-is-Changing-the-Financial-Services-Story-in-Nigeria/58258`,
  },

  //Punch
  {
    coverImage: (
      <StaticImage
        src="../../../assets/images/media/cover_images/punch_cover_image.jpg"
        alt="Punch Cover Image"
      />
    ),
    logo: (
      <StaticImage
        src="../../../assets/images/media/punch.webp"
        alt="Punch logo"
        width={101}
      />
    ),
    date: `4th May, 2021`,
    title: `Eagle Global Markets Celebrates Five
    <br/>Years of Excellence`,
    excerpt: `Conceived in a kitchen in the United Kingdom, and established in the business nucleus of the black continent `,
    url: `https://punchng.com/eagle-global-markets-celebrate-five-years-of-excellence/`,
  },

  // Zikoko
  {
    coverImage: (
      <StaticImage
        src="../../../assets/images/media/cover_images/zikoko_cover_image_1.webp"
        alt="Zikoko Cover Image"
      />
    ),
    logo: (
      <StaticImage
        src="../../../assets/images/media/zikoko.webp"
        alt="Zikoko logo"
        width={88}
      />
    ),
    date: `8th July, 2021`,
    title: `6 reasons why trading is your best bet
    For banishing sapa.
    `,
    excerpt: `If like us, you're tired of Sapa or maybe you just want to keep growing that money tree in your backyard… `,
    url: `https://www.instagram.com/p/CRhLQYyFEh5/?utm_medium=copy_link`,
  },

  // Vanguard
  {
    coverImage: (
      <StaticImage
        src="../../../assets/images/media/cover_images/vanguard_cover_image.webp"
        alt="Vanguard Cover Image"
      />
    ),
    logo: (
      <StaticImage
        src="../../../assets/images/media/vanguard.webp"
        alt="Vanguard logo"
        width={110}
      />
    ),
    date: `8th July, 2021`,
    title: `How Eagle Global Markets is Changing 
    <br/>the Financial Services Story in Nigeria`,
    excerpt: `The financial market is a very integral part of any nation’s economy and for Nigeria, it is no different. With an…`,
    url: `https://www.vanguardngr.com/2021/07/sponsored-how-eagle-global-markets-is-changing-the-financial-services-story-in-nigeria/`,
  },

  //Nairametrics
  {
    coverImage: (
      <StaticImage
        src="../../../assets/images/media/cover_images/nairametrics_cover_image.webp"
        alt="Nairametrics Cover Image"
      />
    ),
    logo: (
      <StaticImage
        src="../../../assets/images/media/nairametrics.webp"
        alt="Nairametrics logo"
        width={147}
      />
    ),
    date: `4th May, 2021`,
    title: `Over the last half of a decade, EGM has
      <br/>been evolving strategically `,
    excerpt: ` CloudTrade, a technological revolution that allowed Nigerians to trade in Naira. Earlier this year…`,
    url: `https://nairametrics.com/2021/07/05/eagle-global-markets-celebrating-5-years-of-excellence/`,
  },

  //Businessday
  {
    coverImage: (
      <StaticImage
        src="../../../assets/images/media/cover_images/businessday_cover_image.webp"
        alt="Business day Cover Image"
      />
    ),
    logo: (
      <StaticImage
        src="../../../assets/images/media/business-day.webp"
        alt="Business day logo"
        width={148}
      />
    ),
    date: `4th May, 2021`,
    title: `How Egmarkets is Changing the Financial
    Services Story in Nigeria `,
    excerpt: ` CloudTrade, a technological revolution that allowed Nigerians to trade in Naira. Earlier this year…`,
    url: `https://businessday.ng/markets/article/eagle-global-markets-seen-changing-financial-services-story-in-nigeria/`,
  },

  // CNBC
  {
    coverImage: (
      <StaticImage
        src="../../../assets/images/media/cover_images/cnbc_cover_image_2.webp"
        alt="CNBC cover image"
      />
    ),
    logo: (
      <StaticImage
        src="../../../assets/images/media/cnbc.webp"
        alt="CNBC News logo"
        width={156}
      />
    ),
    date: `9th Apr, 2021`,
    title: `Global markets continue slide on COVID-19, oil price war.`,
    excerpt: `Global central banks unveil bigger spending measures to counter the effects of the coronavirus outbreak – this…`,
    url: `https://www.cnbcafrica.com/media/6142287450001/`,
  },

  // Zikoko
  {
    coverImage: (
      <StaticImage
        src="../../../assets/images/media/cover_images/zikoko_cover_image.jpg"
        alt="Zikoko Cover Image"
      />
    ),
    logo: (
      <StaticImage
        src="../../../assets/images/media/zikoko.webp"
        alt="Zikoko logo"
        width={88}
      />
    ),
    date: `8th July, 2021`,
    title: `5 Nigerians Talk About How Trading
    <br/>Has Affected Their Lives
    `,
    excerpt: `Being a newbie in trading has taught me a few lessons. I’m focused on learning the basic principles of trading such as `,
    url: `https://www.zikoko.com/money/5-nigerians-talk-about-how-trading-has-affected-their-lives/`,
  },
]
