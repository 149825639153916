import React from "react"
import ctl from "@netlify/classnames-template-literals"

const PressItem = ({ item, index }) => {
  const { coverImage, logo, date, title, excerpt, url } = item
  const pressItemWrapperStyle = ctl(` 
  press-mention 
  ${index % 2 === 0 ? `md:mt-[100px]` : `md:mt-[-120px] `}
  ${index === 0 && `md:mt-[0px] `}
  ${index === 1 && `md:mt-[-200px] `}
  `)

  return (
    <div>
      <section className={pressItemWrapperStyle}>
        <div className={coverImageWrapperStyle}>{coverImage}</div>

        <a href={url} target="_blank " rel="noreferrer">
          <div className={detailsContainerStyle}>
            <div className={logoDateWrapperStyle}>
              <span className={logoStyle}>{logo}</span>

              <span className="date">{date}</span>
            </div>

            <h3
              className={headingStyle}
              dangerouslySetInnerHTML={{ __html: title }}
            ></h3>
            <p className={paragraphStyle}>{excerpt}</p>
          </div>
        </a>
      </section>
    </div>
  )
}

const coverImageWrapperStyle = ctl(` bg-white`)
const detailsContainerStyle = ctl(`md:py-[28px] py-[16px] 
md:px-[32px] px-[24px] max-h-max bg-white`)
const logoDateWrapperStyle = ctl(
  `flex justify-between items-center 
  font-bold  mb-[12px] even:group:text-blue
  md:text-auto md:text-[16px] text-[11px]  `
)

const headingStyle = ctl(`md:text-[20px] leading-normal text-sm`)
const paragraphStyle = ctl(`md:text-[16px] text-[13px] 
md:mt-[15px] mt-[13px]`)

const logoStyle = ctl(`h-full inline-block`)

export default PressItem
