import * as React from "react"
import Layout from "../components/layout"
import PressMentions from "../components/pages/press/mentions"
import Container from "../components/utils/container"

import ctl from "@netlify/classnames-template-literals"

const PressPage = () => (
  <Layout
    title="Egmarkets in the news"
    description='Read all about what people are saying about your number one financial markets trading company.'
  >
    <div className="relative">
      <Container size="large">
        <div className={introSectionStyle}>
          <p className={paragraphStyle}>PRESS</p>
          <h1 className='font-extrabold md-max:text-[32px] md:text-[40px] md:leading-[1.25]'>
            Read about what <br />
            we’ve been up to.
          </h1>
        </div>

        <PressMentions />
      </Container>
    </div>
  </Layout>
)

const introSectionStyle = ctl(
  `max-w-[488px] md:pt-[90px] pt-[20px] md:text-left text-center md:mb-0 mb-[40px] md:mx-0 mx-auto`
)

const paragraphStyle = ctl(
  `uppercase font-medium text-base md:mb-[10px] mb-[10px]`
)

export default PressPage
