import React from "react"
import ctl from "@netlify/classnames-template-literals"

import { pressMentions } from "./data"
import PressItem from "./item"
import ellipseLeft from "../../../assets/images/pages/media/ellipse-with-left-arrow.svg"
import ellipseRight from "../../../assets/images/pages/media/ellipse-with-right-arrow.svg"

const PressMentions = () => {
  return (
    <div className={pressMentionsStyle}>
      <img src={ellipseRight} alt="" className='-z-1 max-w-none md:w-[1807px] right-[-70px] absolute top-[11.096774194%] md-max:hidden'/>
      <img src={ellipseLeft} alt="" className='-z-1 max-w-none md:w-[1807px] left-[-95px] absolute top-[34.795698925%] md-max:hidden'/>
      <img src={ellipseRight} alt="" className='-z-1 max-w-none md:w-[1807px] right-[-70px] absolute top-[69.677419355%] md-max:hidden'/>
      {pressMentions.map((item, index) => {
        return (
          <>
            <PressItem item={item} index={index} key={index} />
          </>
        )
      })}
    </div>
  )
}

const pressMentionsStyle = ctl(
  ` press-mentions grid md:grid-cols-2 grid-cols-1  
   md:gap-y-[0px] gap-y-[60px] 
  lg:gap-x-[170px] gap-x-[50px]
  pb-5 md:pb-[30px] md:mt-[60px] mt-[30px] `
)

export default PressMentions
